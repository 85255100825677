/* Small Screen */
.container {
  background-color: #06D6A0;
  color: #fff;
  padding: 1rem;
  text-align: center;
  font-size: 20px;
}

.footer__title {
  font-family: "DM Serif Display", serif;
}
.footer__author {
  margin-bottom: 1rem;
}

.footer__link {
  color: #fff;
}
/* Medium Screen */
@media (min-width: 768px) {
  /*
   * Nothing TODO here.
   * We dont change styling footer. 
   */
}

/* Large Screen */
@media (min-width: 992px) {
  /*
   * Nothing TODO here.
   * We dont change styling footer. 
   */
}
