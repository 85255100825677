.container {
  padding: 0 2rem;
  margin-bottom: 10rem;
}

.form {
  max-width: 1200px;
  margin: 0 auto;
}

.form__title {
  text-align: center;
  color: #06D6A0;
}

.form__left {
  max-width: 1200px;
  margin: 0 auto;
}
.hero__image{
  width: 100%;
}

.form__group {
  display: flex;
  flex-direction: column;
  margin: 0.9rem 0;
}
label {
  padding: 0.5rem 0;
}
input, select {
  width: 100%;
  padding: 0.8rem;
  border-width: 3px;
}

.form__button button {
  margin-top: 2rem;
  width: 100%;
}

span {
  padding-top: 0.4rem;
  color: #ea3546;
}

button {
  background-color: #06D6A0;
  font-family: "DM Sans", sans-serif;
  font-weight: bold;
  border: 2px solid transparent;
  color: #fff;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: transparent;
  color: #06D6A0;
  border: 3px solid #06D6A0;
  border-width: 3px;
}
@media screen and (min-width: 992px) {
  .form {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .form__left {
    padding: 5rem;
    display: block;
    flex-basis: 50%;
  }

  .hero__image{
    width: 100%;
  }

  .form__right {
    flex-basis: 50%;
    padding: 5rem;
  }
}
