.container {
  padding: 7rem 2rem;
  text-align: center;
}

.provinsi {
  max-width: 1200px;
  margin: 0 auto;
}

.provinsi__title h1 {
  text-align: center;
  color: #06D6A0;
}
.provinsi__title p {
  color: #118AB2;
  text-align: center;
}

.provinsi__body {
  overflow-x: auto;
}

.provinsi__table {
  margin: 2rem auto;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 1000px;
}

.provinsi__table:hover td{
  border-color: #fff;
  background: #06D6A0;
}

.provinsi__table th,
td {
  padding: 2rem 0;
  border-color: #111;
  border-style: solid;
  border-width: 3px;
}
