.container {
  padding-bottom: 3rem;
}

.hero {
  padding: 4rem 2rem;
  color: #fff;
  max-width: 1200px;
  margin: 0 auto;
  color: #06D6A0;
  font-family: "DM Sans", sans-serif;
}

.hero__image{
  width: 100%;
}

.hero__title h1 {
  font-family: "DM Serif Display", serif;
  color: #06D6A0;
}
.hero__title p {
  color: #118AB2;
}

.hero__body {
  margin: 1.2rem 0 2rem 0;
  text-align: justify;
  color: #111;
}

.hero__left {
  margin: 2rem;
}

button {
  background-color: #06D6A0;
  font-family: "DM Sans", sans-serif;
  font-weight: bold;
  border: 2px solid transparent;
  color: #fff;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: transparent;
  color: #06D6A0;
  border: 2px solid #06D6A0;
}
@media screen and (min-width: 768px) {
  .hero {
    padding: 4rem 2rem;
  }
}

@media screen and (min-width: 992px) {
  .hero {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .hero__image{
    width: 100%;
  }

  .herp__left {
    margin: 0;
  }
}
